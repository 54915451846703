import { useQuery } from '@tanstack/vue-query';
import { validatorFactory } from '@laam/lib/validator';
import * as Sentry from '@sentry/vue';
import {
	filtersResponseSchema,
	type FiltersResponseSchema,
} from '@laam/cms-shared';

const filterValidator = validatorFactory<FiltersResponseSchema>(
	filtersResponseSchema,
);

export type FilterParams =
	| {
			brand_handle: string;
			// eslint-disable-next-line no-mixed-spaces-and-tabs
	  }
	| {
			drop_handle: string;
			// eslint-disable-next-line no-mixed-spaces-and-tabs
	  }
	| {
			node_id: string;
			// eslint-disable-next-line no-mixed-spaces-and-tabs
	  }
	| {
			query: string;
			// eslint-disable-next-line no-mixed-spaces-and-tabs
	  }
	| {
			collection_handle: string;
			// eslint-disable-next-line no-mixed-spaces-and-tabs
	  }
	| {};

export const fetchFilters = async (
	params: FilterParams,
	appliedFilterParams: string,
) => {
	const api = createApiInstance();
	let url = '/v1/products/filters';
	const isCollectionHandle = 'collection_handle' in params;
	if (isCollectionHandle) {
		url = `/v1/collections/${params.collection_handle}/products/filters`;
	}
	if (appliedFilterParams) {
		url += `?${appliedFilterParams}`;
	}
	const response = await api.get(url, {
		params: (isCollectionHandle ? {} : params) as FilterParams,
	});
	if (response.status === 200) {
		const body = response.data;

		try {
			return filterValidator.verify(body);
		} catch (e) {
			const err = new Error('Failed to verify filter data');
			Sentry.captureException(err, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw err;
		}
	}
	const err = new Error('Failed to fetch filter data');
	Sentry.captureException(err, (scope) => {
		scope.setContext('response', response.data);
		scope.setContext('params', params);
		return scope;
	});
	throw err;
};

export const useFilters = (
	params: Ref<FilterParams | null>,
	appliedFilterParams: Ref<string>,
) => {
	const enabled = computed(() => !!Object.keys(params.value || {}).length);

	const { data, isLoading, suspense, error } = useQuery<FiltersResponseSchema>({
		queryKey: ['filter', params, appliedFilterParams],
		enabled,
		queryFn: async () => fetchFilters(params.value!, appliedFilterParams.value),
	});
	return { data, isLoading, suspense, error };
};

export const hasCategories = (filter: {} | undefined) => {
	if (filter) {
		return !Object.values(filter).every(
			(category) =>
				!category ||
				Object.keys(category).length === 0 ||
				Object.keys(category) === null,
		);
	}
	return false;
};
