<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { FormField, ToggleButton, Input } from '@laam/ui/base';
import { useField } from 'vee-validate';
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import { computed, inject, ref, type Ref } from 'vue';

const { isMobileView = false } = defineProps<{
	isMobileView?: boolean;
}>();

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();

const filters = inject('filters') as Ref<FiltersResponseSchema>;

const l1Nodes = computed(() => {
	return [...filters.value.nodes!];
});
const l1NodesFiltered = computed(() => {
	if (searchVal.value != '') {
		const result: {
			id: number;
			title: string;
			children: {
				id: number;
				title: string;
			}[];
		}[] = [];
		l1Nodes.value.forEach((item) => {
			result.push({
				id: item.id,
				title: item.title,
				children: item.children.filter((children) =>
					children.title.toLowerCase().includes(searchVal.value.toLowerCase()),
				),
			});
		});
		return result;
	}
	return filters.value.nodes!;
});

const field = useField<string[]>('node_id');
const handleChange = (checked: boolean, value: string) => {
	const fieldValue = field.value.value || [];
	const newVal = checked
		? [...fieldValue, value]
		: fieldValue.filter((v) => v !== value);
	field.setValue(newVal);
	logEvent(value);
};
const logEvent = (style: string) => {
	emit('update-filter', {
		filterType: 'node_id',
		filterValue: style,
		source: window.location.href,
	});
};
const searchVal = ref('');
</script>
<template>
	<div class="flex flex-col style_filter_form gap-3xl">
		<Input
			v-model:model-value="searchVal"
			:auto-focus="false"
			placeholder="Search"
		>
			<template #leftIcon>
				<PhMagnifyingGlass size="16" />
			</template>
		</Input>

		<template v-for="(node, idx) in l1NodesFiltered" :key="node">
			<div class="flex flex-col gap-xl">
				<h2 class="font-semibold capitalize text-md">
					{{ node.title }}
				</h2>
				<div class="flex flex-wrap gap-xl">
					<FormField
						v-for="style in node.children"
						:key="style.id"
						type="checkbox"
						name="node_id"
						:value="style.id.toString()"
						:unchecked-value="false"
					>
						<ToggleButton
							:checked="!!field.value.value?.includes(style.id.toString())"
							:size="isMobileView ? 'xs' : 'sm'"
							class="font-medium capitalize"
							@update:checked="(e) => handleChange(e, style.id.toString())"
						>
							{{ style.title }}
						</ToggleButton>
					</FormField>
				</div>
			</div>
			<div
				v-if="idx !== l1Nodes.length - 1"
				class="h-[1px] bg-gray-200 w-full"
			/>
		</template>
	</div>
</template>
