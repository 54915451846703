<script setup lang="ts">
import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
	Button,
} from '@laam/ui/base';
import { PhX } from '@phosphor-icons/vue';
import AllFilterForm from './forms/AllFilterForm.vue';

const emits = defineEmits<{
	(e: 'reset-form'): void;
}>();

const open = ref(false);
const setFiltersFromUrl = inject<() => void>('setFiltersFromUrl');
const filterCount = inject('filterCount') as Ref<number>;
</script>

<template>
	<Drawer v-model:open="open" class="overflow-clip">
		<DrawerTrigger>
			<slot />
		</DrawerTrigger>
		<DrawerContent
			side="bottom"
			class="size_filter_sheet_content p-0"
			slot-class="!p-0 overflow-clip"
			:on-open-auto-focus="(e) => e.preventDefault()"
			:on-close-auto-focus="(e) => e.preventDefault()"
			@pointer-down-outside="setFiltersFromUrl"
		>
			<DrawerHeader
				class="p-4 h-10xl flex flex-row align-middle justify-between border-b border-gray-200"
			>
				<DrawerTitle class="text-gray-900">Filters</DrawerTitle>
				<DrawerClose data-testid="close-dialog-button">
					<div class="cursor-pointer">
						<PhX size="18" :color="'#000000'" :weight="'bold'" />
					</div>
					<span class="sr-only">Close</span>
				</DrawerClose>
			</DrawerHeader>
			<div class="grow">
				<AllFilterForm />
			</div>
			<DrawerFooter
				class="flex flex-row justify-end h-11xl w-full py-xl px-3xl border-t border-gray-200"
			>
				<Button
					size="md"
					variant="secondary"
					class="w-1/4 h-9xl"
					@click="
						() => {
							emits('reset-form');
						}
					"
				>
					Reset
				</Button>

				<DrawerTrigger :as-child="true">
					<Button
						size="md"
						variant="primary"
						class="grow bg-gray-800 w-3/4 h-9xl"
						type="submit"
						form="filterForm"
					>
						{{ 'Apply ' + (filterCount ? `(${filterCount})` : '') }}
					</Button>
				</DrawerTrigger>
			</DrawerFooter>
		</DrawerContent>
	</Drawer>
</template>
