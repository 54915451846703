<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { ToggleButton, FormField, Input, Button } from '@laam/ui/base';
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import { computed, inject, ref, reactive, type Ref } from 'vue';

const { isMobileView = false } = defineProps<{
	isMobileView?: boolean;
}>();

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();

const searchVal = ref('');
const filters = inject('filters') as Ref<FiltersResponseSchema>;
const categories = computed(() => {
	return [
		'fabric',
		'shirt-fabrics',
		'dupatta-fabric',
		'bottom-fabric',
		'trouser-fabrics',
		'fabric painting',
		'poly fabric',
	];
});

const PAGE_LIMIT = 20;

const fabricTree = reactive<{
	[key: string]: {
		items: string[];
		currentPage: number;
		totalPages: number;
	};
}>({});

const updateFabricTree = () => {
	if (searchVal.value !== '') {
		categories.value.forEach((category: string) => {
			if (!filters.value.fabrics) {
				throw new Error('fabric filters undefined');
			}
			const items =
				filters.value.fabrics[
					category as keyof typeof filters.value.fabrics
				]?.filter((item: string) =>
					item.toLowerCase().includes(searchVal.value.toLowerCase()),
				) ?? [];
			fabricTree[category] = {
				items,
				currentPage: 1,
				totalPages: Math.ceil(items.length / PAGE_LIMIT),
			};
		});
	} else {
		Object.keys(filters.value.fabrics || {}).forEach((fabricType) => {
			const fabricKey = fabricType as keyof typeof filters.value.fabrics;
			const items = filters.value.fabrics?.[fabricKey] || [];

			fabricTree[fabricType] = {
				items,
				currentPage: 1,
				totalPages: Math.ceil(items.length / PAGE_LIMIT),
			};
		});
	}
};

updateFabricTree();

const slicedFabricTree = computed(() => {
	const slicedResult: {
		[key: string]: {
			items: string[];
			currentPage: number;
			totalPages: number;
		};
	} = {};

	Object.keys(fabricTree).forEach((key) => {
		const fabric = fabricTree[key];

		slicedResult[key] = {
			items: fabric!.items.slice(0, fabric!.currentPage * PAGE_LIMIT),
			currentPage: fabric!.currentPage,
			totalPages: fabric!.totalPages,
		};
	});

	return slicedResult;
});

const logEvent = (fabric: string) => {
	emit('update-filter', {
		filterType: 'fabrics',
		filterValue: fabric,
		source: window.location.href,
	});
};

const incrementPage = (category: string) => {
	if (fabricTree[category]!.currentPage < fabricTree[category]!.totalPages) {
		fabricTree[category]!.currentPage += 1;
	}
};
</script>
<template>
	<div class="fabric_filter_form gap-3xl flex flex-col">
		<Input
			v-model:model-value="searchVal"
			:auto-focus="false"
			placeholder="Search"
			@input="updateFabricTree"
		>
			<template #leftIcon>
				<PhMagnifyingGlass size="16" />
			</template>
		</Input>
		<template v-for="category in categories" :key="category">
			<div
				v-if="
					slicedFabricTree[category as keyof typeof fabricTree]!.items.length >
					0
				"
				class="gap-xl flex flex-col"
			>
				<h2
					v-if="!!category && categories.length > 1"
					class="text-md font-semibold capitalize"
				>
					{{ category.replace('-', ' ') }}
				</h2>
				<div class="gap-xl flex flex-wrap">
					<FormField
						v-for="fabric in slicedFabricTree[
							category as keyof typeof fabricTree
						]?.items!"
						v-slot="{ handleChange, value }"
						:key="fabric"
						type="checkbox"
						name="attrs"
						:value="`${category}.${fabric}`"
						:unchecked-value="false"
					>
						<ToggleButton
							:checked="value?.includes(`${category}.${fabric}`)"
							:size="isMobileView ? 'xs' : 'sm'"
							class="font-medium capitalize"
							@update:checked="
								(e) => {
									handleChange(e);
									logEvent(`${category}.${fabric}`);
								}
							"
						>
							{{ fabric }}
						</ToggleButton>
					</FormField>
					<Button
						v-if="
							fabricTree[category]!.currentPage <
							fabricTree[category]!.totalPages
						"
						class="font-medium"
						variant="secondary"
						@click="incrementPage(category)"
					>
						...
					</Button>
				</div>
			</div>

			<div
				v-if="
					category !== categories[categories.length - 1] &&
					slicedFabricTree[category as keyof typeof slicedFabricTree]!.items
						.length > 0
				"
				class="h-[1px] bg-gray-200 w-full"
			/>
		</template>
	</div>
</template>
