<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { FormField, ToggleButton, Button } from '@laam/ui/base';
import { useField } from 'vee-validate';
import { computed, inject, ref, type Ref } from 'vue';

const { isMobileView = false } = defineProps<{
	isMobileView?: boolean;
}>();

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();

const filters = inject('filters') as Ref<FiltersResponseSchema>;

const field = useField<string[]>('attrs');
const handleChange = (checked: boolean, value: string) => {
	const fieldValue = field.value.value || [];
	const newVal = checked
		? [...fieldValue, value]
		: fieldValue.filter((v) => v !== value);
	field.setValue(newVal);
	logEvent(value);
};
const formatAttribute = (attr: string) => {
	return attr
		.split('/')
		.map((a) => a.charAt(0).toUpperCase() + a.slice(1))
		.join(' / ');
};

const occassions = computed(() => filters.value['attrs.product-type']);
const PAGE_LIMIT = 10;

const currentPage = ref(1);

const totalPages = computed(() =>
	Math.ceil(occassions.value!.length / PAGE_LIMIT),
);

const slicedOccassionsList = computed(() => {
	return occassions.value!.slice(0, currentPage.value * PAGE_LIMIT);
});

const handlePageChange = () => {
	currentPage.value++;
};

const logEvent = (occassion: string) => {
	emit('update-filter', {
		filterType: 'occassions',
		filterValue: occassion,
		source: window.location.href,
	});
};
</script>
<template>
	<div class="flex flex-col style_filter_form gap-3xl">
		<div class="flex flex-wrap gap-xl">
			<FormField
				v-for="(attr, idx) in slicedOccassionsList"
				:key="idx"
				type="checkbox"
				name="attrs"
				:value="`product-type.${attr}`"
				:unchecked-value="false"
			>
				<ToggleButton
					:checked="!!field.value.value?.includes(`product-type.${attr}`)"
					:size="isMobileView ? 'xs' : 'sm'"
					class="font-medium capitalize"
					@update:checked="(e) => handleChange(e, `product-type.${attr}`)"
				>
					{{ formatAttribute(attr) }}
				</ToggleButton>
			</FormField>
			<Button
				v-if="currentPage < totalPages"
				class="font-medium"
				variant="secondary"
				@click="handlePageChange"
			>
				...
			</Button>
		</div>
	</div>
</template>
