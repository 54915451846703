<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { FormField, ToggleButton, Input, Button } from '@laam/ui/base';
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import { computed, inject, ref, type Ref } from 'vue';

const { isMobileView = false } = defineProps<{
	isMobileView?: boolean;
}>();

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();

const filters = inject('filters') as Ref<FiltersResponseSchema>;
const searchVal = ref('');
const noOfPieces = computed(() => {
	let allPieces = filters.value['attrs.number-of-pieces'];
	if (searchVal.value != '') {
		allPieces = allPieces?.filter((piece: string) =>
			piece.toLowerCase().includes(searchVal.value.toLowerCase()),
		);
	}
	return (
		allPieces?.map((piece) => ({
			label: piece,
			value: piece,
		})) || []
	);
});

const PAGE_LIMIT = 20;

const currentPage = ref(1);

const totalPages = computed(() =>
	Math.ceil(noOfPieces.value.length / PAGE_LIMIT),
);

const slicedPiecesList = computed(() => {
	return noOfPieces.value.slice(0, currentPage.value * PAGE_LIMIT);
});

const handlePageChange = () => {
	currentPage.value++;
};

const logEvent = (noOfPieces: string) => {
	emit('update-filter', {
		filterType: 'number-of-pieces',
		filterValue: noOfPieces,
		source: window.location.href,
	});
};
</script>
<template>
	<div class="flex flex-wrap no_of_pieces_filter_form gap-xl">
		<Input
			v-model:model-value="searchVal"
			:auto-focus="false"
			placeholder="Search"
		>
			<template #leftIcon>
				<PhMagnifyingGlass size="16" />
			</template>
		</Input>
		<FormField
			v-for="piece in slicedPiecesList"
			v-slot="{ handleChange, value }"
			:key="piece.value"
			type="checkbox"
			name="attrs"
			:value="`number-of-pieces.${piece.value}`"
			:unchecked-value="false"
		>
			<ToggleButton
				:checked="!!value.includes(`number-of-pieces.${piece.value}`)"
				:size="isMobileView ? 'xs' : 'sm'"
				class="font-medium capitalize"
				@update:checked="
					(e) => {
						handleChange(e);
						logEvent(`number-of-pieces.${piece.value}`);
					}
				"
			>
				{{ piece.label }}
			</ToggleButton>
		</FormField>
		<Button
			v-if="currentPage < totalPages"
			class="font-medium"
			variant="secondary"
			@click="handlePageChange"
		>
			...
		</Button>
	</div>
</template>
