<script setup lang="ts">
import { Button } from '@laam/ui/base';
import { PhCaretDown } from '@phosphor-icons/vue';
import { activeFilterStyle } from './utils';
import { useField } from 'vee-validate';
import type { FiltersResponseSchema } from '@laam/cms-shared';
import FilterDialog from './FilterDialog.vue';
import FilterDrawer from './FilterDrawer.vue';

const emits = defineEmits<{
	(e: 'reset-form'): void;
}>();

const filters = inject('filters') as globalThis.Ref<FiltersResponseSchema>;

const field = useField<string[] | undefined>('size');

const show = computed(() => !!filters.value?.size);
const filterCount = computed(() => field.value.value?.length || 0);

provide('selectedFilter', 'size');
</script>
<template>
	<div v-if="show">
		<FilterDrawer v-if="isMobileView()" @reset-form="() => emits('reset-form')">
			<Button
				:size="'xs'"
				variant="secondary"
				class="size_filter_sheet_trigger"
				:class="filterCount ? activeFilterStyle : ''"
			>
				<span class="lg:px-xxs">
					{{ 'Size ' + (filterCount > 0 ? `(${filterCount})` : '') }}
				</span>

				<PhCaretDown :weight="'bold'" />
			</Button>
		</FilterDrawer>

		<FilterDialog v-else @reset-form="() => emits('reset-form')">
			<Button
				:size="'md'"
				variant="secondary"
				class="size_filter_sheet_trigger"
				:class="filterCount ? activeFilterStyle : ''"
			>
				<span class="lg:px-xxs">
					{{ 'Size ' + (filterCount > 0 ? `(${filterCount})` : '') }}
				</span>

				<PhCaretDown :weight="'bold'" />
			</Button>
		</FilterDialog>
	</div>
</template>
