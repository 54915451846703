<script setup lang="ts">
import { IconButton } from '@laam/ui/base';

import { PhSlidersHorizontal } from '@phosphor-icons/vue';
import FilterDialog from './FilterDialog.vue';
import FilterDrawer from './FilterDrawer.vue';

const emits = defineEmits<{
	(e: 'reset-form'): void;
}>();
</script>
<template class="size_filter_sheet">
	<FilterDrawer v-if="isMobileView()" @reset-form="() => emits('reset-form')">
		<IconButton :size="'xs'" variant="secondary" rounded="full">
			<PhSlidersHorizontal />
		</IconButton>
	</FilterDrawer>
	<FilterDialog v-else @reset-form="() => emits('reset-form')">
		<IconButton rounded="full" variant="secondary" size="nav-button">
			<PhSlidersHorizontal />
		</IconButton>
	</FilterDialog>
</template>
