<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { ToggleButton, FormField } from '@laam/ui/base';
import { computed, inject, type Ref } from 'vue';

const { isMobileView = false } = defineProps<{
	isMobileView?: boolean;
}>();

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();

const filters = inject('filters') as Ref<FiltersResponseSchema>;

const sizeTree = computed(() => filters.value.size!);

const categories = computed(() => {
	const categories = Object.keys(sizeTree.value);
	return [...categories];
});
const logEvent = (size: string) => {
	emit('update-filter', {
		filterType: 'size',
		filterValue: size,
		source: window.location.href,
	});
};
</script>
<template>
	<div class="size_filter_form gap-3xl flex flex-col">
		<template v-for="category in categories" :key="category">
			<div class="gap-xl flex flex-col">
				<h2
					v-if="!!category && categories.length > 1"
					class="text-md font-semibold capitalize"
				>
					{{ `${category} size` }}
				</h2>
				<div class="gap-xl flex flex-wrap">
					<FormField
						v-for="size in sizeTree[category as keyof typeof sizeTree]!"
						v-slot="{ handleChange, value }"
						:key="size"
						type="checkbox"
						name="size"
						:value="`${category}.${size}`"
						:unchecked-value="false"
					>
						<ToggleButton
							:checked="value?.includes(`${category}.${size}`)"
							:size="isMobileView ? 'xs' : 'sm'"
							class="font-medium capitalize"
							@update:checked="
								(e) => {
									handleChange(e);
									logEvent(`${category}.${size}`);
								}
							"
						>
							{{ size }}
						</ToggleButton>
					</FormField>
				</div>
			</div>
			<div
				v-if="category !== categories[categories.length - 1]"
				class="h-[1px] bg-gray-200 w-full"
			/>
		</template>
	</div>
</template>
