<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { FormField, ToggleButton, Input, Button } from '@laam/ui/base';
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import { computed, inject, ref, type Ref } from 'vue';

const { isMobileView = false } = defineProps<{
	isMobileView?: boolean;
}>();

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();

const filters = inject('filters') as Ref<FiltersResponseSchema>;
const searchVal = ref('');
const workTechnique = computed(() => {
	let techniques = filters.value['attrs.work-technique'];
	if (searchVal.value != '') {
		techniques = techniques?.filter((technique: string) =>
			technique.toLowerCase().includes(searchVal.value.toLowerCase()),
		);
	}
	return (
		techniques?.map((technique) => ({
			label: technique,
			value: technique,
		})) || []
	);
});

const PAGE_LIMIT = 20;

const currentPage = ref(1);

const totalPages = computed(() =>
	Math.ceil(workTechnique.value.length / PAGE_LIMIT),
);

const slicedWorkTechniqueList = computed(() => {
	return workTechnique.value.slice(0, currentPage.value * PAGE_LIMIT);
});

const handlePageChange = () => {
	currentPage.value++;
};

const logEvent = (workTechnique: string) => {
	emit('update-filter', {
		filterType: 'work-technique',
		filterValue: workTechnique,
		source: window.location.href,
	});
};
</script>
<template>
	<div class="flex flex-wrap work_technique_filter_form gap-xl">
		<Input
			v-model:model-value="searchVal"
			:auto-focus="false"
			placeholder="Search"
		>
			<template #leftIcon>
				<PhMagnifyingGlass size="16" />
			</template>
		</Input>
		<FormField
			v-for="technique in slicedWorkTechniqueList"
			v-slot="{ handleChange, value }"
			:key="technique.value"
			type="checkbox"
			name="attrs"
			:value="`work-technique.${technique.value}`"
			:unchecked-value="false"
		>
			<ToggleButton
				:checked="!!value.includes(`work-technique.${technique.value}`)"
				:size="isMobileView ? 'xs' : 'sm'"
				class="font-medium capitalize"
				@update:checked="
					(e) => {
						handleChange(e);
						logEvent(`work-technique.${technique.value}`);
					}
				"
			>
				{{ technique.label }}
			</ToggleButton>
		</FormField>
		<Button
			v-if="currentPage < totalPages"
			class="font-medium"
			variant="secondary"
			@click="handlePageChange"
		>
			...
		</Button>
	</div>
</template>
