<script setup lang="ts">
import { Button } from '@laam/ui/base';
import { PhCaretDown } from '@phosphor-icons/vue';
import { activeFilterStyle } from './utils';
import { useField } from 'vee-validate';
import type { FiltersResponseSchema } from '@laam/cms-shared';
import FilterDialog from './FilterDialog.vue';
import FilterDrawer from './FilterDrawer.vue';

const emits = defineEmits<{
	(e: 'reset-form'): void;
}>();

const filters = inject('filters') as globalThis.Ref<FiltersResponseSchema>;

const field = useField<string[] | undefined>('node_id');

const show = computed(() => !!filters.value?.nodes?.length);
const filterCount = computed(() => field.value.value?.length || 0);

provide('selectedFilter', 'nodes');
</script>
<template class="style_filter_sheet">
	<div v-if="show">
		<FilterDrawer v-if="isMobileView()" @reset-form="() => emits('reset-form')">
			<Button
				size="xs"
				variant="secondary"
				class="style_filter_sheet_trigger"
				:class="filterCount ? activeFilterStyle : ''"
			>
				{{ 'Styles ' + (filterCount > 0 ? `(${filterCount})` : '') }}
				<PhCaretDown :weight="'bold'" />
			</Button>
		</FilterDrawer>

		<FilterDialog v-else @reset-form="() => emits('reset-form')">
			<Button
				size="md"
				variant="secondary"
				class="style_filter_sheet_trigger"
				:class="filterCount ? activeFilterStyle : ''"
			>
				{{ 'Styles ' + (filterCount > 0 ? `(${filterCount})` : '') }}
				<PhCaretDown :weight="'bold'" />
			</Button>
		</FilterDialog>
	</div>
</template>
