<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { FormField, ToggleButton } from '@laam/ui/base';
import { computed, inject, type Ref } from 'vue';

const { isMobileView = false } = defineProps<{
	isMobileView?: boolean;
}>();

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();
const filters = inject('filters') as Ref<FiltersResponseSchema>;

const percentages = computed(() => {
	return (
		filters.value.sale_percentage?.map((percentage) => ({
			label: `${percentage.split('_').join(' ')}`,
			value: percentage,
		})) || []
	);
});

const logEvent = (percentage: string) => {
	emit('update-filter', {
		filterType: 'sale_percentage',
		filterValue: percentage,
		source: window.location.href,
	});
};
</script>
<template>
	<div class="gap-xl flex flex-wrap">
		<FormField
			v-for="(item, index) in percentages"
			v-slot="{ handleChange, value }"
			:key="index"
			class="sort_filter_form"
			name="sale_percentage"
			type="radio"
		>
			<ToggleButton
				:size="isMobileView ? 'xs' : 'sm'"
				class="font-medium capitalize"
				:checked="value?.includes(item.value)"
				@update:checked="
					() => {
						handleChange(item.value);
						logEvent(item.value);
					}
				"
			>
				{{ item.label }}
			</ToggleButton>
		</FormField>
	</div>
</template>
