<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { ExpressBadge, Toggle } from '@laam/ui/base';
import { useField } from 'vee-validate';
import { cn } from '@laam/lib/utils';
import { type HTMLAttributes } from 'vue';

const props = withDefaults(
	defineProps<{
		class?: HTMLAttributes['class'];
		inForm?: boolean;
	}>(),
	{
		class: '',
		inForm: false,
	},
);

const emits = defineEmits<{
	(e: 'update:expressToggle'): void;
}>();

const filters = inject('filters') as globalThis.Ref<FiltersResponseSchema>;

const field = useField<string[]>('delivery_days');

const show = computed(
	() => !!filters.value?.delivery_days?.find((t) => t.includes('readytoship')),
);

const handleCheckboxChange = (value: boolean) => {
	if (value) {
		const uniqueDeliveryFilters = new Set(field?.value.value);
		uniqueDeliveryFilters.add('readytoship');
		field?.setValue([...uniqueDeliveryFilters]);
	} else {
		const uniqueDeliveryFilters = new Set(field?.value.value);
		uniqueDeliveryFilters.delete('readytoship');
		field?.setValue([...uniqueDeliveryFilters]);
	}
	emits('update:expressToggle');
};
</script>
<template>
	<div
		v-if="show"
		:class="
			cn(
				'flex items-center border express_toggle px-lg py-md gap-md lg:px-xl lg:gap-xs rounded-medium w-fit',
				props.class,
			)
		"
	>
		<div v-if="inForm" class="flex flex-row gap-xs items-center">
			<ExpressBadge />
			<slot />
		</div>

		<ExpressBadge v-else />

		<Toggle
			class="express_toggle_button"
			:checked="!!field?.value.value?.includes('readytoship')"
			@update:checked="handleCheckboxChange"
		/>
	</div>
</template>
