<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { FormField, ToggleButton, Button } from '@laam/ui/base';
import { inject, type Ref, ref, computed } from 'vue';

const { isMobileView = false } = defineProps<{
	isMobileView?: boolean;
}>();

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();

const filters = inject('filters') as Ref<FiltersResponseSchema>;

const seasons = computed(() => {
	return (
		filters.value['attrs.season']?.map((season) => ({
			label: season,
			value: season,
		})) || []
	);
});

const PAGE_LIMIT = 10;

const currentPage = ref(1);

const totalPages = computed(() => Math.ceil(seasons.value.length / PAGE_LIMIT));

const slicedSeasonsList = computed(() => {
	return seasons.value.slice(0, currentPage.value * PAGE_LIMIT);
});

const handlePageChange = () => {
	currentPage.value++;
};

const logEvent = (season: string) => {
	emit('update-filter', {
		filterType: 'season',
		filterValue: season,
		source: window.location.href,
	});
};
</script>
<template>
	<div class="flex flex-wrap season_filter_form gap-xl">
		<FormField
			v-for="season in slicedSeasonsList"
			v-slot="{ handleChange, value }"
			:key="season.value"
			type="checkbox"
			name="attrs"
			:value="`season.${season.value}`"
			:unchecked-value="false"
		>
			<ToggleButton
				:checked="!!value.includes(`season.${season.value}`)"
				:size="isMobileView ? 'xs' : 'sm'"
				class="font-medium capitalize"
				@update:checked="
					(e) => {
						handleChange(e);
						logEvent(`season.${season.value}`);
					}
				"
			>
				{{ season.label }}
			</ToggleButton>
		</FormField>

		<Button
			v-if="currentPage < totalPages"
			class="font-medium"
			variant="secondary"
			@click="handlePageChange"
		>
			...
		</Button>
	</div>
</template>
