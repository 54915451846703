<script setup lang="ts">
import { Button } from '@laam/ui/base';
import { PhCaretDown } from '@phosphor-icons/vue';
import { activeFilterStyle } from './utils';
import { useFormValues } from 'vee-validate';
import type { FilterSchema, FiltersResponseSchema } from '@laam/cms-shared';
import FilterDialog from './FilterDialog.vue';
import FilterDrawer from './FilterDrawer.vue';

const emits = defineEmits<{
	(e: 'reset-form'): void;
}>();

const filters = inject('filters') as globalThis.Ref<FiltersResponseSchema>;

const appliedFilters = useFormValues<FilterSchema>();

const show = computed(() => !!filters.value?.prices);
const filterCount = computed(() => {
	if (appliedFilters.value.price) {
		if (
			filters.value.prices?.max_price !== appliedFilters.value.price.to ||
			filters.value.prices?.min_price !== appliedFilters.value.price.from
		) {
			return 1;
		}
	}
	return 0;
});

provide('selectedFilter', 'prices');
</script>
<template>
	<div v-if="show" data-testid="plp-price-filter">
		<FilterDrawer v-if="isMobileView()" @reset-form="() => emits('reset-form')">
			<Button
				size="xs"
				variant="secondary"
				class="price_filter_drawer_trigger"
				:class="filterCount ? activeFilterStyle : ''"
			>
				Price
				<PhCaretDown :weight="'bold'" />
			</Button>
		</FilterDrawer>

		<FilterDialog v-else @reset-form="() => emits('reset-form')">
			<Button
				size="md"
				variant="secondary"
				class="price_filter_drawer_trigger"
				:class="filterCount ? activeFilterStyle : ''"
			>
				Price
				<PhCaretDown :weight="'bold'" />
			</Button>
		</FilterDialog>
	</div>
</template>
