<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { FormField, ToggleButton } from '@laam/ui/base';
import { computed, inject, ref, type Ref } from 'vue';

const { isMobileView = false } = defineProps<{
	isMobileView?: boolean;
}>();

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();

const filters = inject('filters') as Ref<FiltersResponseSchema>;

const deliveryOptions = computed(() => filters.value.delivery_days);

const deliveries = computed(() => {
	return (
		deliveryOptions.value?.map((delivery) => ({
			label: delivery,
			value: delivery,
		})) || []
	);
});

const PAGE_LIMIT = 10;

const currentPage = ref(1);

const totalPages = computed(() =>
	Math.ceil(deliveries.value.length / PAGE_LIMIT),
);

const slicedDeliveriesList = computed(() => {
	return deliveries.value.slice(0, currentPage.value * PAGE_LIMIT);
});

const handlePageChange = () => {
	currentPage.value++;
};

const logEvent = (delivery: string) => {
	emit('update-filter', {
		filterType: 'delivery_days',
		filterValue: delivery,
		source: window.location.href,
	});
};
</script>
<template>
	<div class="flex flex-wrap delivery_filter_form gap-xl">
		<FormField
			v-for="delivery in slicedDeliveriesList"
			:key="delivery.value"
			v-slot="{ handleChange, value }"
			type="checkbox"
			name="delivery_days"
			:value="delivery.value"
			:unchecked-value="false"
		>
			<ToggleButton
				:checked="value.includes(delivery.value)"
				:size="isMobileView ? 'xs' : 'sm'"
				class="font-medium capitalize"
				@update:checked="
					(e) => {
						handleChange(e);
						logEvent(delivery.value);
					}
				"
			>
				{{
					delivery.label == 'readytoship' ? 'Express Shipping' : delivery.label
				}}
			</ToggleButton>
		</FormField>
		<Button
			v-if="currentPage < totalPages"
			class="font-medium"
			variant="secondary"
			@click="handlePageChange"
		>
			...
		</Button>
	</div>
</template>
