<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { FormField, ToggleButton, Input, Button } from '@laam/ui/base';
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import { computed, type Ref } from 'vue';
import { inject, ref } from 'vue';

const { isMobileView = false } = defineProps<{
	isMobileView?: boolean;
}>();

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();

const filters = inject('filters') as Ref<FiltersResponseSchema>;
const searchVal = ref('');
const colors = computed(() => {
	let allColors = filters.value['attrs.color-type'];
	if (searchVal.value != '') {
		allColors = allColors?.filter((color: string) =>
			color.toLowerCase().includes(searchVal.value.toLowerCase()),
		);
	}
	return (
		allColors?.map((color) => ({
			label: color,
			value: color,
		})) || []
	);
});

const PAGE_LIMIT = 20;

const currentPage = ref(1);

const totalPages = computed(() => Math.ceil(colors.value.length / PAGE_LIMIT));

const slicedColorsList = computed(() => {
	return colors.value.slice(0, currentPage.value * PAGE_LIMIT);
});

const handlePageChange = () => {
	currentPage.value++;
};

const logEvent = (color: string) => {
	emit('update-filter', {
		filterType: 'color',
		filterValue: color,
		source: window.location.href,
	});
};
</script>
<template>
	<div class="flex flex-wrap color_filter_form gap-xl">
		<Input
			v-model:model-value="searchVal"
			:auto-focus="false"
			placeholder="Search"
		>
			<template #leftIcon>
				<PhMagnifyingGlass size="16" />
			</template>
		</Input>

		<div class="flex flex-wrap gap-xl">
			<FormField
				v-for="color in slicedColorsList"
				v-slot="{ handleChange, value }"
				:key="color.value"
				type="checkbox"
				name="attrs"
				:value="`color-type.${color.value}`"
				:unchecked-value="false"
			>
				<ToggleButton
					:checked="!!value.includes(`color-type.${color.value}`)"
					:size="isMobileView ? 'xs' : 'sm'"
					class="font-medium capitalize"
					@update:checked="
						(e) => {
							handleChange(e);
							logEvent(`color-type.${color.value}`);
						}
					"
				>
					{{ color.label }}
				</ToggleButton>
			</FormField>
			<Button
				v-if="currentPage < totalPages"
				class="font-medium"
				variant="secondary"
				@click="handlePageChange"
			>
				...
			</Button>
		</div>
	</div>
</template>
