<script setup lang="ts">
import {
	Dialog,
	DialogTrigger,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogClose,
	DialogFooter,
	Button,
} from '@laam/ui/base';
import { PhX } from '@phosphor-icons/vue';
import type { TObject } from '@sinclair/typebox';
import AllFilterForm from './forms/AllFilterForm.vue';

const emits = defineEmits<{
	(e: 'reset-form'): void;
}>();

const validate = inject('validate') as () => Promise<TObject>;
const setFiltersFromUrl = inject<() => void>('setFiltersFromUrl');
const filterCount = inject('filterCount') as Ref<number>;

const open = ref(false);

const toggleOpen = async () => {
	const validationResponse = await validate();
	if (validationResponse.valid) {
		open.value = !open.value;
	}
};
</script>

<template>
	<Dialog v-model:open="open">
		<DialogTrigger as-child>
			<slot />
		</DialogTrigger>
		<DialogContent
			class="px-0 py-0 !rounded-3xl !gap-0 w-[45%] h-fit"
			:show-close="false"
			@pointer-down-outside="setFiltersFromUrl"
		>
			<DialogDescription class="w-full h-full">
				<DialogHeader
					:show-close="true"
					class="p-4 h-11xl flex-row align-middle justify-between"
				>
					<DialogTitle class="text-gray-900">Filters</DialogTitle>
					<DialogClose data-testid="close-dialog-button">
						<div class="cursor-pointer">
							<PhX size="18" :color="'#000000'" :weight="'bold'" />
						</div>
						<span class="sr-only">Close</span>
					</DialogClose>
				</DialogHeader>
				<AllFilterForm />
				<DialogFooter
					class="flex justify-end h-12xl w-full p-3xl border-t border-gray-200"
				>
					<Button
						size="lg"
						class="w-1/6"
						variant="secondary"
						@click="
							() => {
								emits('reset-form');
							}
						"
					>
						Reset
					</Button>

					<Button
						size="lg"
						variant="primary"
						class="grow bg-gray-800 w-5/6"
						type="submit"
						form="filterForm"
						data-testid="apply-filters"
						@click="toggleOpen"
					>
						{{ 'Apply ' + (filterCount ? `(${filterCount})` : '') }}
					</Button>
				</DialogFooter>
			</DialogDescription>
		</DialogContent>
	</Dialog>
</template>
