<script setup lang="ts">
import { Button } from '@laam/ui/base';
import { PhCaretDown } from '@phosphor-icons/vue';
// import SizeFilterForm from './forms/SizeFilterForm.vue';
import { activeFilterStyle } from './utils';
import { useField } from 'vee-validate';
import type { FiltersResponseSchema } from '@laam/cms-shared';
import FilterDialog from './FilterDialog.vue';
import FilterDrawer from './FilterDrawer.vue';

const emits = defineEmits<{
	(e: 'reset-form'): void;
}>();

const filters = inject('filters') as globalThis.Ref<FiltersResponseSchema>;

const field = useField<string[] | undefined>('attrs');

const show = computed(() => !!filters.value?.fabrics);
const filterCount = computed(() => {
	const fabricFilters = field.value.value?.filter((item) =>
		item.includes('fabric'),
	);
	return fabricFilters?.length || 0;
});

provide('selectedFilter', 'fabrics');
</script>
<template>
	<div v-if="show">
		<FilterDrawer v-if="isMobileView()" @reset-form="() => emits('reset-form')">
			<Button
				:size="'xs'"
				variant="secondary"
				class="fabric_filter_sheet_trigger"
				:class="filterCount ? activeFilterStyle : ''"
			>
				<div class="flex justify-center items-center gap-sm">
					<span class="lg:px-xxs">
						{{ 'Fabric ' + (filterCount > 0 ? `(${filterCount})` : '') }}
					</span>
				</div>

				<PhCaretDown :weight="'bold'" />
			</Button>
		</FilterDrawer>
		<FilterDialog v-else @reset-form="() => emits('reset-form')">
			<Button
				:size="'md'"
				:variant="'secondary'"
				class="fabric_filter_sheet_trigger"
				:class="filterCount ? activeFilterStyle : ''"
			>
				<div class="flex justify-center items-center gap-sm">
					<span class="lg:px-xxs">
						{{ 'Fabric ' + (filterCount > 0 ? `(${filterCount})` : '') }}
					</span>
				</div>
				<PhCaretDown :weight="'bold'" />
			</Button>
		</FilterDialog>
	</div>
</template>
