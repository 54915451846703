<script setup lang="ts">
import { Button } from '@laam/ui/base';
import { PhCaretDown } from '@phosphor-icons/vue';
import { activeFilterStyle } from './utils';
import { useField } from 'vee-validate';
import type { FiltersResponseSchema } from '@laam/cms-shared';
import FilterDialog from './FilterDialog.vue';
import FilterDrawer from './FilterDrawer.vue';

const emits = defineEmits<{
	(e: 'reset-form'): void;
}>();

const filters = inject('filters') as globalThis.Ref<FiltersResponseSchema>;

const field = useField<string[] | undefined>('attrs');

const show = computed(() => !!filters.value?.['attrs.color-type']);
const filterCount = computed(() => {
	const colorFilters = field.value.value?.filter((item) =>
		item.includes('color-type'),
	);
	return colorFilters?.length || 0;
});

provide('selectedFilter', 'attrs.color-type');
</script>
<template>
	<div v-if="show">
		<FilterDrawer v-if="isMobileView()" @reset-form="() => emits('reset-form')">
			<Button
				:size="'xs'"
				variant="secondary"
				class="color_filter_sheet_trigger"
				:class="filterCount ? activeFilterStyle : ''"
			>
				<div class="flex justify-center items-center gap-sm">
					<span class="lg:px-xxs">
						{{ 'Color ' + (filterCount > 0 ? `(${filterCount})` : '') }}
					</span>
				</div>
				<PhCaretDown :weight="'bold'" />
			</Button>
		</FilterDrawer>

		<FilterDialog v-else @reset-form="() => emits('reset-form')">
			<Button
				:size="'md'"
				variant="secondary"
				class="color_filter_sheet_trigger"
				:class="filterCount ? activeFilterStyle : ''"
			>
				<div class="flex justify-center items-center gap-sm">
					<span class="lg:px-xxs">
						{{ 'Color ' + (filterCount > 0 ? `(${filterCount})` : '') }}
					</span>
				</div>
				<PhCaretDown :weight="'bold'" />
			</Button>
		</FilterDialog>
	</div>
</template>
